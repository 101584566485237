
<template>
  <div class="notificationPage">
    <div class="title">แจ้งเตือน</div>
    <div class="wrapper">
      <div class="h100">
        <div class="content">
          <div v-for="(noti, index) in notificationList" :key="index">
            <NotificationItem 
              type="agent"
              :notification="noti"
              />
          </div>
        </div>
        <scroll-loader
          :loader-method="!loading ? () => getNotifications() : () => {}"
          :loader-disable="endList || error"
          :loader-size="0"
          class="reset">
            <OrderListLoader :error="error" :nodata="nodata" :fetching="loading" />
        </scroll-loader> 
        <DefaultDisplayStage 
          :nodata="this.nodata"
          :fetching="this.loading"
        />
        <div v-if="!error && !nodata" style="height: 100px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from "./notification/NotificationItem";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import OrderListLoader from "@/components/loader/OrderListLoader";

import { GET } from "@/utils/http";

export default {
  name: "notificationPage",
  components: {NotificationItem,DefaultDisplayStage,OrderListLoader},
  data() {
    return {
      notificationList :[],
      error: false,
      loading: false,
      page: 1,
      endList: false,
    };
  },
  mounted() {
    this.getNotifications();
  },
  computed: {
    nodata() {
      return (
        this.endList === true && this.notificationList.length === 0 && this.page == 1
      );
    },
  },
  methods: {
    async getNotifications() {
      this.loading = true;
      const { body, error } = await GET(`/api/shops/${this.$myShop.id}/notifications?page=${this.page}&limit=10&order=createdAt:desc`);

      this.loading = false;

      if (error) {
        this.error = true;
        return;
      }

      this.notificationList.push(...body.data);

      if (this.page >= body.lastPage) {
        this.endList = true;
      } else {
        this.page++;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.notificationPage {
  background: #fff;
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.title {
  font-size: 18px;
  padding: 14px;
  color: #606060;
  font-weight: bold;
}
.wrapper{
    height: 100%;
    overflow-y: auto;
}
.content{
  padding:16px 8px
  
}
</style>
