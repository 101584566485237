
<template>
  <div class="card">
    <div class="content-container">
      <div>
        <div v-if="notification.event === 'new_order'" class="title new">
          คุณได้รับออเดอร์ใหม่
        </div>
        <div
          v-else-if="notification.event === 'order_confirmed'"
          class="title new"
        >
          ออเดอร์ของคุณได้รับการยืนยัน
        </div>
        <div
          v-else-if="notification.event === 'inform_payment'"
          class="title new"
        >
          ออเดอร์ได้รับการชำระเงิน
        </div>
        <div
          v-else-if="notification.event === 'order_shipped'"
          class="title new"
        >
          ออเดอร์ของคุณกำลังจัดส่ง
        </div>
        <div
          v-else-if="notification.event === 'order_cancelled'"
          class="title danger"
        >
          ออเดอร์ของคุณถูกยกเลิก
        </div>
      </div>
      <div>
        {{ $formatDate(notification.createdAt) }}
      </div>
    </div>
    <div>
      <strong>ออเดอร์ </strong>
      {{ this.notification.order.orderNumber }}
    </div>

    <div v-if="isAgent">
      <div>
        <strong>ลูกค้า </strong
        ><span :class="[notification.order.buyer.shopType, 'shopName']">{{
          this.notification.order.buyer.name
        }}</span>
      </div>
      <div>
        <strong>ประเภทลูกค้า </strong
        ><span>{{ getTextKey(notification.order.buyer.shopType) }}</span>
      </div>
    </div>
    <div v-else>
      <strong>ร้านค้า </strong>{{ this.notification.order.seller.name }}
    </div>
    <div class="status-container">
      <strong>สถานะ </strong>
      <div class="tags" v-if="status != null">
        <div :class="this.status.s">
          {{ this.status.n }}
        </div>
      </div>
    </div>
    <div class="content-container">
      <div class="note-container">
        <strong> หมายเหตุ </strong>
        {{ this.notification.note }}
      </div>
      <div class="detailBtn-wrapper">
        <div
          @click.stop="$router.push('/order/' + notification.order.id)"
          class="detailBtn"
        >
          ดูรายละเอียด
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getTextKey from "@/utils/getTextKey";

export default {
  components: {},
  props: {
    type: String,
    notification: {},
  },
  data() {
    return {};
  },
  computed: {
    isAgent() {
      if (this.type == "agent") {
        return true;
      }
      return false;
    },
    status() {
      const status = this.notification?.event;

      if (status === "new_order") {
        return {
          s: "danger",
          n: "ที่ต้องยืนยัน",
        };
      } else if (status === "order_confirmed") {
        return {
          s: "danger",
          n: "ที่ต้องชำระ",
        };
      } else if (status === "inform_payment") {
        return {
          s: "danger",
          n: "ที่ต้องตรวจสอบ",
        };
      } else if (status === "order_shipped") {
        return {
          s: "danger",
          n: "ที่ต้องยืนยันรับ",
        };
      } else if (status === "order_cancelled") {
        return {
          s: "danger",
          n: "ยกเลิก",
        };
      }
      return null;
    },
  },
  methods: {
    getTextKey: getTextKey,
  },
};
</script>
<style lang="scss" scoped>
.shopName {
  &.sub-agent {
    color: #93c47d;
  }
  &.sub-direct {
    color: #93c47d;
  }
  &.agent {
    color: #38761d;
  }
  &.retail {
    color: #4a86e8;
  }
}

.card {
  color: rgb(88, 88, 88);
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 600px;
  margin: 0px auto 16px auto;
  font-size: 14px;
  line-height: 25px;
  overflow: hidden;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 15px rgb(0 0 0 / 15%);
}
.title {
  font-weight: bold;
  font-size: 16px;

  &.new {
    color: var(--primary-color);
  }
  &.danger {
    color: #da291c;
  }
}

.content-container {
  display: flex;
  justify-content: space-between;
  word-break: break-all;
}
.status-container {
  display: flex;
  > strong {
    margin-right: 8px;
  }
}
.note-container {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.detailBtn {
  width: 100px;
  font-size: 14px;
  border-radius: 6px;
  padding: 3px 9px;
  font-weight: bold;
  border: 2px solid rgb(218, 218, 218);
  color: rgb(145, 145, 145);
  &:active {
    background: rgba(0, 0, 0, 0.05);
  }

  &-wrapper {
    align-self: flex-end;
    margin-left: 8px;
  }
}

.tags {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  .warning,
  .danger,
  .pass,
  .cancel {
    padding: 3px 7px;
    border-radius: 30px;
    margin-right: 6px;
  }
  .warning {
    color: #f67b00;
    background: #fff2cd;
  }
  .danger {
    color: #da291c;
    background: #f4cbcc;
  }
  .pass {
    color: var(--primary-color);
    background: #d9ead3ff;
  }
  .cancel {
    color: #ffffff;
    background: rgb(129, 129, 129);
  }
}
</style>
